import { LoadingButton } from '@mui/lab';
import { Alert, Box, Card, Container, FormHelperText, Snackbar, Stack, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingBox } from '../components/LoadingBox';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { useHttpClient } from '../hooks/useHttpClient';
import { slugify } from '../utils/slugify';
//
import 'react-quill/dist/quill.snow.css';

export default function CareerDetail() {
  const [openNotification, setOpenNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const { http } = useHttpClient();
  const { id } = useParams();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DetailSchema = Yup.object().shape({
    slug: Yup.string().required('Slug is required').max(200),
    title: Yup.string().required('Title is required'),
    content: Yup.string().required('Content is required'),
  });

  const formik = useFormik({
    initialValues: {
      id: undefined,
      slug: '',
      title: '',
      description: '',
      content: '',
      status: 0,
    },
    validationSchema: DetailSchema,
    onSubmit: async (data) => {
      await save(data);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues, setFieldValue } = formik;

  async function load() {
    if (id === 'new') {
      return;
    }
    setLoading(true);
    await http.get(`jobs/${id}`).then(setValues);
    setLoading(false);
  }

  async function save(detail) {
    if (detail.id > 0) {
      await http.put('jobs', detail);
    } else {
      const id = await http.post('jobs', detail);
      setFieldValue('id', id);
    }
    setOpenNotification(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotification(false);
  };

  const handleTitleChange = (event) => {
    if (!values.slug || slugify(values.title) === values.slug) {
      setFieldValue('slug', slugify(event.target.value));
    }
    setFieldValue('title', event.target.value);
  };

  const handleSlugChange = (event) => {
    setFieldValue('slug', slugify(event.target.value));
  };

  return (
    <Page title="Career Detail">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            <Link to="/careers">Career</Link>
          </Typography>
          <Typography variant="h4" gutterBottom>
            Detail
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <LoadingBox loading={loading} />
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack sx={{ padding: '20px' }} spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="title"
                    type="text"
                    label="Career Title"
                    {...getFieldProps('title')}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    onChange={handleTitleChange}
                  />

                  <TextField
                    fullWidth
                    autoComplete="slug"
                    type="text"
                    label="Url Slug"
                    {...getFieldProps('slug')}
                    onChange={handleSlugChange}
                    error={Boolean(touched.slug && errors.slug)}
                    helperText={errors.slug || 'Please use descriptive and meaningful 3-5 words.'}
                  />

                  <TextField
                    fullWidth
                    autoComplete="description"
                    type="text"
                    label="Summary"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />

                  <FormGroup>
                    <FormControlLabel
                      label="Publish?"
                      control={<Checkbox checked={values.status === 1} />}
                      onChange={(e) => setFieldValue('status', e.target.checked ? 1 : 0)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormControlLabel
                      style={{ margin: 0 }}
                      label="Content"
                      labelPlacement="top"
                      control={
                        <ReactQuill
                          theme="snow"
                          style={{ height: 400, width: '100%', marginBottom: 40 }}
                          value={values.content}
                          onChange={(val) => setFieldValue('content', val)}
                        />
                      }
                    />
                    <FormHelperText error required>
                      {touched.content || errors.content}
                    </FormHelperText>
                  </FormGroup>
                </Stack>
                <Box sx={{ padding: '20px' }}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://bigbrolis.com/career/${values.id}?perform=administrator-purpose`}
                  >
                    Show on site
                  </a>
                </Box>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </Scrollbar>
        </Card>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        message="Save Success"
        autoHideDuration={6000}
        open={openNotification}
        onClose={handleClose}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Save Success!
        </Alert>
      </Snackbar>
    </Page>
  );
}
