import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import * as React from 'react';

LoadingBox.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.number,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export function LoadingBox({ loading, size=40, top='50%' }) {
  return (
    loading ? (
      <Box sx={{ height: 40, position: 'absolute', left: `calc(50% - ${size / 2}px)`, top, zIndex: 99999 }}>
        <CircularProgress size={size} />
      </Box>
    ) : null
  );
}
