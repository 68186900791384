export function parseParms(str) {
  const pieces = str.split("&")
  const data = {};
  let parts = [];
  // process each query pair
  for (let i = 0; i < pieces.length; i += 1) {
      parts = pieces[i].split("=");
      if (parts.length < 2) {
          parts.push("");
      }
      data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
  }
  return data;
}