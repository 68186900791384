import { LoadingButton } from '@mui/lab';
import { Alert, Card, Container, Snackbar, Stack, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingBox } from '../components/LoadingBox';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { useHttpClient } from '../hooks/useHttpClient';
import { ClientInfo } from '../sections/@dashboard/common';
//
import 'react-quill/dist/quill.snow.css';

export default function CareerDetail() {
  const [openNotification, setOpenNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const { http } = useHttpClient();
  const { id } = useParams();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DetailSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    message: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      id: undefined,
      name: '',
      email: '',
      message: '',
      client_info: null,
      status: 0,
    },
    validationSchema: DetailSchema,
    onSubmit: async (data) => {
      await save(data);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues, setFieldValue } = formik;

  async function load() {
    if (id === 'new') {
      return;
    }
    setLoading(true);
    await http.get(`contacts/${id}`).then(setValues);
    setLoading(false);
  }

  async function save(detail) {
    if (detail.id > 0) {
      await http.put('contacts', detail);
    } else {
      const id = await http.post('contacts', detail);
      setFieldValue('id', id);
    }
    setOpenNotification(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotification(false);
  };

  const hadleLoadIploc = async () => {
    try {
      const app = await http.put('/contacts/update-iploc', values)
      const clientInfo = { ...values.client_info, iploc: app.client_info.iploc };
      setValues({ ...values, client_info: clientInfo });
    } catch (err) {
      const clientInfo = { ...values.client_info, errMsg: err.message };
      setValues({ ...values, client_info: clientInfo });
    }
  };

  return (
    <Page title="Contact Detail">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            <Link to="/contacts">Contact</Link>
          </Typography>
          <Typography variant="h4" gutterBottom>
            Detail
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <LoadingBox loading={loading} />
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack sx={{ padding: '20px' }} spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="name"
                    type="text"
                    label="Name"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <TextField
                    fullWidth
                    autoComplete="email"
                    type="text"
                    label="Email"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    label="Message"
                    {...getFieldProps('message')}
                    error={Boolean(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                  />

                  <FormGroup>
                    <FormControlLabel
                      label="Active?"
                      control={<Checkbox checked={values.status === 1} />}
                      onChange={(e) => setFieldValue('status', e.target.checked ? 1 : 0)}
                    />
                  </FormGroup>

                  <ClientInfo clientInfo={values.client_info} onLoadIploc={hadleLoadIploc} />
                </Stack>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </Scrollbar>
        </Card>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        message="Save Success"
        autoHideDuration={6000}
        open={openNotification}
        onClose={handleClose}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Save Success!
        </Alert>
      </Snackbar>
    </Page>
  );
}
