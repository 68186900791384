import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import { useHttpClient } from './useHttpClient';

export function useUserAuth() {
  const { user, setUser } = useAuth();
  const [error, setError] = useState(null);
  const location = useLocation();
  const { http } = useHttpClient();
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const loginUser = async (email, password, remember) => {
    await http
      .post('login', { email, password }, { withCredentials: remember })
      .then((user) => {
        setUser(user);
        navigate('/dashboard');
      })
      .catch((err) => {
        setError(err);
        throw err;
      });
  };

  // call this function to sign out logged in user
  const logoutUser = async () => {
    await http
      .get('/logout', { withCredentials: true })
      .then(() => {
        http.defaults.headers.common.Authorization = ``;
        setUser(undefined);
        navigate('/login', { replace: true });
      })
      .catch((err) => {
        setError(err);
      });
  };

  const refreshToken = async () => {
    await http
      .post('/token', { token: user && user.token }, { withCredentials: true })
      .then((user) => {
        if (user) {
          setUser(user);
        } else {
          setUser(undefined);
          navigate(`/login#redirect=${encodeURIComponent(location.pathname)}`, { replace: true });
        }
      })
      .catch(() => {
        navigate(`/login#redirect=${encodeURIComponent(location.pathname)}`, { replace: true });
      });
  };

  return useMemo(
    () => ({
      user,
      loginUser,
      logoutUser,
      refreshToken,
      error,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );
}
