import { useState } from 'react';
import { applySortFilter, getComparator, ROWS_PER_PAGE } from '../utils/tableUtils';

export function useTableUtils(options = {}) {
  options = { list: [], order: 'asc', rowsPerPage: ROWS_PER_PAGE, ...options };

  const { list } = options;

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState(options.order);

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(options.orderBy);

  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(options.rowsPerPage);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelected = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterByStatus = (event) => {
    setFilterStatus(event.target.checked ? 1 : 0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredUsers = applySortFilter(options.orderBy, list, getComparator(order, orderBy), {
    filterName,
    filterStatus,
  });

  const isRecordNotFound = filteredUsers.length === 0;

  return {
    page,
    handleChangeRowsPerPage,
    handleChangePage,
    order,
    selected,
    handleSelected,
    handleSelectAllClick,
    orderBy,
    handleRequestSort,
    filterName,
    filteredUsers,
    handleFilterByName,
    handleFilterByStatus,
    setSelected,
    rowsPerPage,
    emptyRows,
    isRecordNotFound,
  };
}
