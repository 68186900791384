import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Career from './pages/Career';
import Application from './pages/Application';
import ApplicationDetail from './pages/ApplicationDetail';
import CareerDetail from './pages/CareerDetail';
import Contact from './pages/Contact';
import ContactDetail from './pages/ContactDetail';
import DashboardApp from './pages/DashboardApp';
import Login from './pages/Login';
import NotFound from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'careers', element: <Career /> },
        { path: 'careers/detail/:id', element: <CareerDetail /> },
        { path: 'applications', element: <Application /> },
        { path: 'applications/detail/:id', element: <ApplicationDetail /> },
        { path: 'contacts', element: <Contact /> },
        { path: 'contacts/detail/:id', element: <ContactDetail /> },
      ],
    },
    {
      path: '',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
