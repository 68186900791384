// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'career',
    path: 'careers',
    icon: getIcon('gg:work-alt'),
  },
  {
    title: 'application',
    path: 'applications',
    icon: getIcon('cil:touch-app'),
  },
  {
    title: 'contact',
    path: 'contacts',
    icon: getIcon('cil:envelope-letter'),
  }
];

export default navConfig;
