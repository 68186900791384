import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';

export function ModalConfirm(props) {
  const { title, children, open, onClose, ...rest } = props;

  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }} maxWidth="xs" open={open} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button onClick={() => onClose(true)}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}


ModalConfirm.propTypes = {
  id: PropTypes.string,
  keepMounted: PropTypes.bool,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
};