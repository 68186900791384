import { LoadingButton } from '@mui/lab';
import { Alert, Card, Container, Link as LinkMui, Snackbar, Stack, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingBox } from '../components/LoadingBox';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { getApiHost, useHttpClient } from '../hooks/useHttpClient';
import { useUserAuth } from '../hooks/useUserAuth';
import { ClientInfo } from '../sections/@dashboard/common';

export default function ApplicationDetail() {
  const [openNotification, setOpenNotification] = useState(0);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const { user } = useUserAuth();
  const { http } = useHttpClient();
  const { id } = useParams();

  useEffect(() => {
    load();
    loadCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DetailSchema = Yup.object().shape({
    firstname: Yup.string().required(),
    linkedin: Yup.string().url().nullable(),
    email: Yup.string().email(),
  });

  const formik = useFormik({
    initialValues: {
      id: undefined,
      firstname: '',
      surname: '',
      linkedin: '',
      email: '',
      country_id: '',
      city: '',
      phone: '',
      preface: '',
      iploc: '',
      cv_file: '',
      status: 0,
    },
    validationSchema: DetailSchema,
    onSubmit: async (data) => {
      await save(data);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues, setFieldValue } = formik;

  async function loadCountries() {
    await http.get(`/countries`).then(setCountries);
  }

  async function load() {
    if (id === 'new') {
      return;
    }
    setLoading(true);
    await http.get(`job-applications/${id}`).then(setValues);
    setLoading(false);
  }

  async function save(detail) {
    try {
      if (detail.id > 0) {
        await http.put('job-applications', detail);
      } else {
        const id = await http.post('job-applications', detail);
        setFieldValue('id', id);
      }
      setOpenNotification(1);
    } catch (err) {
      setOpenNotification(-1);
    }
  }

  async function handleDownloadCv() {
    const endpoint = `${getApiHost()}/job-applications/${values.id}/cv-file`;
    const options = {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const blob = await axios.get(endpoint, options);

    const url = window.URL.createObjectURL(blob.data);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Cv_${values.firstname}_${values.surname}_${new Date(values.created_at).toISOString().slice(0, 10)}_${
      values.cv_file
    }`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotification(0);
  };

  const hadleLoadIploc = async () => {
    try {
      const app = await http.put('/job-applications/update-iploc', values);
      const clientInfo = { ...values.client_info, iploc: app.client_info.iploc };
      setValues({ ...values, client_info: clientInfo });
    } catch (err) {
      const clientInfo = { ...values.client_info, errMsg: err.message };
      setValues({ ...values, client_info: clientInfo });
    }
  };

  return (
    <Page title="Career Application Detail">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            <Link to="/applications">CareerApplication</Link>
          </Typography>
          <Typography variant="h4" gutterBottom>
            Detail
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <LoadingBox loading={loading} />
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack sx={{ padding: '20px' }} spacing={3}>
                  <TextField
                    fullWidth
                    autoComplete="firstname"
                    type="text"
                    label="First name"
                    {...getFieldProps('firstname')}
                    error={Boolean(touched.firstname && errors.firstname)}
                    helperText={touched.firstname && errors.firstname}
                  />

                  <TextField
                    fullWidth
                    autoComplete="surname"
                    type="text"
                    label="Last name"
                    {...getFieldProps('surname')}
                    error={Boolean(touched.surname && errors.surname)}
                    helperText={touched.surname && errors.surname}
                  />

                  <TextField
                    fullWidth
                    autoComplete="email"
                    type="text"
                    label="Email"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <Select
                    fullWidth
                    label="Country"
                    {...getFieldProps('country_id')}
                    error={Boolean(touched.country_id && errors.country_id)}
                    // helperText={touched.country_id && errors.country_id}
                  >
                    <MenuItem key={'def'} disabled value={undefined} />
                    {countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <TextField
                    fullWidth
                    autoComplete="city"
                    type="text"
                    label="City"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />

                  <TextField
                    fullWidth
                    autoComplete="phone"
                    type="text"
                    label="Phone"
                    {...getFieldProps('phone')}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />

                  <TextField
                    fullWidth
                    autoComplete="linkedin"
                    type="text"
                    label="Linkedin"
                    {...getFieldProps('linkedin')}
                    error={Boolean(touched.linkedin && errors.linkedin)}
                    helperText={touched.linkedin && errors.linkedin}
                  />

                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    label="Cover"
                    {...getFieldProps('preface')}
                    error={Boolean(touched.preface && errors.preface)}
                    helperText={touched.preface && errors.preface}
                  />

                  <LinkMui onClick={() => handleDownloadCv()}>Download Cv File</LinkMui>

                  <FormGroup>
                    <FormControlLabel
                      label="Active?"
                      control={<Checkbox checked={values.status === 1} />}
                      onChange={(e) => setFieldValue('status', e.target.checked ? 1 : 0)}
                    />
                  </FormGroup>
                  <ClientInfo clientInfo={values.client_info} onLoadIploc={hadleLoadIploc} />
                </Stack>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                  Save
                </LoadingButton>
              </Form>
            </FormikProvider>
          </Scrollbar>
        </Card>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        message="Save Success"
        autoHideDuration={6000}
        open={openNotification !== 0}
        onClose={handleClose}
      >
        <Alert severity={openNotification === 1 ? 'success' : 'error'} sx={{ width: '100%' }}>
          {openNotification === 1 ? 'Save Success!' : 'An error occured!'}
        </Alert>
      </Snackbar>
    </Page>
  );
}
