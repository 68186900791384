import { Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({searchQuery, ...props}) {
  return (
    <Paper {...props}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Not record found for "{searchQuery}"
      </Typography>
    </Paper>
  );
}
