import { filter } from 'lodash';

export const ROWS_PER_PAGE = 10;

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  if (! orderBy) {
    return null;
  }
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(field, array, comparator, query) {
  if (comparator) {
    array = sort(array, comparator);
  }
  if (query.filterName || query.filterStatus) {
    return filter(array, (rec) => {
      if ( (!query.filterStatus || rec.status === 1)) {
        return true;
      }
      if (query.filterName && rec[field].toLowerCase().indexOf(query.filterName.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    });
  }
  return array;
}

function sort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}