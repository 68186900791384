import axios from 'axios';
import { useEffect } from 'react';
import { useAuth } from './useAuth';

const http = axios.create({
  baseURL: getApiHost(),
});

http.defaults.headers.common['Content-Type'] = 'application/json';

http.interceptors.response.use(
  (response) => typeof response.data.data !== 'undefined' ? response.data.data : response,
  (error) => {
    if ((error.response.data && error.response.data.statusCode === 401) || error.response.status === 401) {
      window.location.href = `/login#redirect=${encodeURIComponent(window.location.pathname)}`;
    }

    const apiErr = error.response.data && error.response.data.error;

    const err = {
      message: apiErr ? apiErr.description : error.message,
      type: apiErr && apiErr.type,
      statusCode: apiErr ? apiErr.statusCode : error.response.status,
      error,
    };

    return Promise.reject(err);
  }
);

export const useHttpClient = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      http.defaults.headers.common.Authorization = ``;
      return;
    }
    http.defaults.headers.common.Authorization = `Bearer ${user.token}`;
  }, [user]);

  return { http };
};

export function getApiHost() {
  return window.location.hostname === 'localhost' ? 'http://localhost:8080/api' : 'https://bigbrolis.com/api';
}
