import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null);

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.any,
};
