import { Alert, Box, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Iconify from '../../../components/Iconify';
import { LoadingBox } from '../../../components/LoadingBox';

// ----------------------------------------------------------------------

ClientInfo.propTypes = {
  clientInfo: PropTypes.shape({
    ip: PropTypes.string,
    ua: PropTypes.string,
    ref: PropTypes.string,
    iploc: PropTypes.string,
    errMsg: PropTypes.string,
  }),
  onLoadIploc: PropTypes.func,
};

export default function ClientInfo({ clientInfo, onLoadIploc }) {
  const [anchorEl, setAnchorEl] = useState(null);

  if (!clientInfo) {
    return null;
  }

  const handleIpInfo = (event) => {
    event.preventDefault();
    setAnchorEl(event.target);
    if (!clientInfo.iploc) {
      onLoadIploc();
    }
  };

  const handleIpInfoClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <List dense>
        <ListItem>
          <ListItemText
            primary={
              <>
                {clientInfo.ip}{' '}
                <IconButton onClick={handleIpInfo} title="Gather ip details">
                  <Iconify icon="eos-icons:ip" width={18} height={18} />
                </IconButton>
              </>
            }
            secondary={'Ip'}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={clientInfo.ua} secondary={'User Agent'} />
        </ListItem>
        <ListItem>
          <ListItemText primary={clientInfo.ref} secondary={'Referer'} />
        </ListItem>
      </List>
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleIpInfoClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ padding: 2 }} minHeight={200} minWidth={400}>
          <Typography variant="h4">IP Location</Typography>
          <LoadingBox loading={clientInfo.iploc === undefined && clientInfo.errMsg === undefined} />
          {clientInfo.errMsg && <Alert severity="error">{clientInfo.errMsg}</Alert>}
          {clientInfo.iploc && (
            <div>
              <pre>{JSON.stringify(JSON.parse(clientInfo.iploc), null, 2)}</pre>
            </div>
          )}
        </Box>
      </Popover>
    </>
  );
}
