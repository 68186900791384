import {
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Label from '../components/Label';
import { LoadingBox } from '../components/LoadingBox';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { useHttpClient } from '../hooks/useHttpClient';
import { useTableUtils } from '../hooks/useTableUtils';
import { TableHeader, TableToolbar, TableMoreMenu } from '../sections/@dashboard/common';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Job Title' },
  { id: 'firstname', label: 'First Name' },
  { id: 'surname', label: 'Last Name' },
  { id: 'email', label: 'Email' },
  { id: 'linkedin', label: 'Linkedin profile' },
  { id: 'status', label: 'Status' },
  { id: 'created_at', label: 'Creation Date' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function Application() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { http } = useHttpClient();

  const {
    page,
    handleChangeRowsPerPage,
    handleChangePage,
    order,
    selected,
    setSelected,
    handleSelected,
    handleSelectAllClick,
    orderBy,
    handleRequestSort,
    filterName,
    filteredUsers,
    handleFilterByName,
    handleFilterByStatus,
    rowsPerPage,
    emptyRows,
    isRecordNotFound,
  } = useTableUtils({ list });

  useEffect(() => {
    loadList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadList() {
    setLoading(true);
    await http.get('job-applications').then(setList);
    setLoading(false);
  }

  async function handleDelete(id) {
    setLoading(true);
    await http.delete(`job-applications/${id}`);
    await loadList();
  }

  async function handleArchiveClick() {
    setLoading(true);
    Promise.all(selected.map((id) => http.put(`job-applications`, { id, status: 0 }))).then(() => {
      loadList();
      setSelected([]);
      setLoading(false);
    });
  }

  return (
    <Page title="Application List">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Application
          </Typography>
        </Stack>

        <Card>
          <LoadingBox loading={loading} />
          <TableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onFilterStatus={handleFilterByStatus}
            onArchiveClick={() => handleArchiveClick()}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, minHeight: loading ? 350 : undefined }}>
              <Table>
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, firstname, surname, linkedin, email, status, created_at: createdAt } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onChange={(event) => handleSelected(event, id)} />
                        </TableCell>
                        <TableCell align="left">
                          <Link to={`/applications/detail/${id}`}>{title}</Link>
                        </TableCell>
                        <TableCell align="left">{firstname}</TableCell>
                        <TableCell align="left">{surname}</TableCell>
                        <TableCell align="left">{linkedin}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={status === 1 ? 'success' : 'error'}>
                            {status === 1 ? 'Active' : 'Archived'}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{new Date(createdAt).toLocaleDateString('en-GB')}</TableCell>
                        <TableCell align="right">
                          <TableMoreMenu onDelete={() => handleDelete(id)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>

                {!loading && isRecordNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
